import { ref, computed } from "vue"
import { defineStore } from "pinia"
import { apiClient } from "/src/http/http"
import { Organization, OrganizationId } from "../models/organization"
import { User } from "../models/user"

export const useAuthStore = defineStore("user", () => {
    const currentUser = ref<User | null>(null)
    const currentUserOrganizations = ref<Organization[]>([])

    // NOTE: we wrap the currentOrganizatioId attribute in a getter/setter so that
    //  we can automatically store the ID in the browser's localStorage and load it
    //  from there on page refresh so that the user does not automatically get logged
    //  into a new organization when they refresh the page.
    const _currentOrganizationId = ref<OrganizationId | null>(null)
    const currentOrganizationId = computed<OrganizationId | null>({
        get() {
            _currentOrganizationId.value =
                localStorage.getItem("currentOrganizationId") || null
            return _currentOrganizationId.value
        },
        set(value: OrganizationId | null) {
            if (value) localStorage.setItem("currentOrganizationId", value)
            else localStorage.removeItem("currentOrganizationId")
            _currentOrganizationId.value = value
        },
    })

    const currentOrganization = computed(() => {
        if (!currentOrganizationId.value) return null

        const organization = currentUserOrganizations.value.find(
            (org) => org.id === currentOrganizationId.value
        )

        // If there is no organization, then the org id is not valid, so we clear it
        if (!organization) currentOrganizationId.value = null

        return organization || null
    })

    const isOrganizationUploadLimitExceeded = computed(() => {
        if (!currentOrganization.value) return true
        if (isSuperAdmin.value) return false

        return (
            currentOrganization.value.n_documents_uploaded >=
            currentOrganization.value.limit_documents_amount
        )
    })

    const isOrganizationProcessingLimitExceeded = computed(() => {
        if (!currentOrganization.value) return true
        if (isSuperAdmin.value) return false

        return (
            currentOrganization.value.n_documents_processed >=
            currentOrganization.value.limit_documents_processings
        )
    })

    const currentUserRole = computed(
        () => currentOrganization.value?.user_role ?? null
    )

    const csrfToken = ref<string | null>(null)
    const apiKey = ref<string | null>(null)

    const isLoggedIn = computed(() => currentUser.value != null)

    const isRegistrationComplete = computed(
        () =>
            currentUser.value &&
            currentUser.value.registered &&
            currentUserOrganizations.value.length > 0
    )

    const isSuperAdmin = computed(
        () => currentUser.value?.is_superadmin === true
    )

    const isAdmin = computed(
        () =>
            currentUserRole.value === "owner" ||
            currentUserRole.value == "admin" ||
            isSuperAdmin.value
    )

    const getCsrfToken = async () => {
        // try {
        //     const response = await apiClient.get("/user/csrf")
        //     csrfToken.value = response.response.csrf_token
        // } catch (error) {
        //     // TODO: redirect to error page
        //     console.log(error)
        // }
    }

    const retrieveUser = async () => {
        const {
            user,
            organizations,
        }: { user: User; organizations: Organization[] } =
            await apiClient.get("/user")
        currentUser.value = user
        currentUserOrganizations.value = organizations

        if (!currentOrganizationId.value)
            currentOrganizationId.value = organizations[0]?.id ?? null
    }

    const logout = async () => {
        await apiClient.post("/logout")

        currentUser.value = null
        currentOrganizationId.value = null

        window.location.href = "/"
    }

    const changeOrganization = (newOrganizationId: OrganizationId) => {
        currentOrganizationId.value = newOrganizationId
    }

    return {
        currentUser,
        currentOrganization,
        currentUserOrganizations,
        currentUserRole,
        csrfToken,
        apiKey,
        isLoggedIn,
        isRegistrationComplete,
        isSuperAdmin,
        isAdmin,
        isOrganizationProcessingLimitExceeded,
        isOrganizationUploadLimitExceeded,
        changeOrganization,
        getCsrfToken,
        retrieveUser,
        logout,
    }
})
